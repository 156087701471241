.topnav-dropdown-header {
    @include margin-padding(null, 15px);
    border-bottom: 1px solid $light-900;
    .notification-title {
        font-size: $font-size-18;
        font-weight: $font-weight-semibold;
        color: $black;
        margin-bottom: 0;
    }
}
.notification-dropdown {
    .noti-content {
        width: 440px;
        height: 390px;
        overflow-y: auto;
        position: relative;
    }
    .notification-list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-top: 0;
            border-bottom: 1px solid $light-600;
            font-size: $font-size-base;
            &:last-child {
                border-bottom: 0;
            }
            a {
                display: block;
                @include margin-padding(null, 15px);
            }
            .avatar {
                width: 36px;
                height: 36px;
                margin: 0 16px 0 0;
                border-radius: $border-radius-lg;
                position: relative;
                img {
                    border-radius: $border-radius-lg;
                }
                .badge {
                    display: inline-block;
                    position: absolute;
                    top: -7px;
                    left: -7px;
                    padding: 4px;
                }
            }
            .noti-title {
                color: $gray-800;
                font-weight: $font-weight-medium;
            }
            .noti-details {
                font-weight: $font-weight-medium;
                color: $gray-400;
                @include margin-padding(0 0 8px, null);
            }
            .noti-sub-details {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                color: $gray-400;
                position: relative;
                @include margin-padding(0 0 8px, 0 0 0 15px);
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 4px;
                    height: -webkit-fill-available;
                    background: $gray-400;
                    border-radius: $border-radius-lg;
                } 
            }
            p.noti-time {
                font-size: $font-size-12;
                color: $gray-900;
                @include margin-padding(0, null);
            }
            .noti-pdf {
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                border: 1px solid $light-900;
                border-radius: $border-radius-lg;
                padding: 10px;
                margin-bottom: 8px;
                .noti-pdf-icon {
                    margin-right: 10px;
                    span {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        justify-content: center;
                        -webkit-justify-content: center;
                        -ms-flex-pack: center;
                        color: $primary;
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                        border: 1px solid $light-900;
                        border-radius: $border-radius-lg;
                    }
                }
                .noti-pdf-text {
                    line-height: normal;
                    p {
                        font-size: $font-size-12;
                        color: $gray-900;
                        margin-bottom: 0;
                    }
                    span {
                        font-size: $font-size-12;
                        color: $text-color;
                    }
                }
            }
            .notification-time {
                font-size: $font-size-12;
                line-height: 1.35;
                color: $gray-600;
            }
            .list-item {
                border: 0;
                padding: 0;
                position: relative;
                .list-left {
                    height: 48px;
                    position: absolute;
                    width: 48px;
                }
                .list-body {
                    @include margin-padding(null, 0 0 0 50px);
                    .message-author {
                        color: $gray-800;
                        float: left;
                        font-weight: $font-weight-medium;
                        width: 175px;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .message-time {
                        color: $gray-600;
                        float: right;
                        font-size: $font-size-11;
                    }
                    .message-content {
                        color: $gray-800;
                        font-size: $font-size-13;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
.topnav-dropdown-footer {
    background: $light-200;
    text-align: right;
    @include margin-padding(null, 15px);
    a {
        font-weight: $font-weight-semibold;
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
    .view-link {
        color: $gray-900;
        &:hover {
            color: $gray-400;
        }
    }
    .clear-link {
        color: $primary;
        &:hover {
            color: darken($primary, 10%);
        }
    }
}
.list-left {
    height: 48px;
    position: absolute;
    width: 48px;
}
.list-body {
    @include margin-padding(null, 0 0 0 50px);
    .message-author {
        color: $gray-800;
        float: left;
        font-weight: $font-weight-medium;
        width: 175px;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .message-time {
        color: $gray-600;
        float: right;
        font-size: $font-size-11;
    }
    .message-content {
        color: $gray-800;
        font-size: $font-size-13;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.activity {
    width: 100%;
    .activity-box {
        position: relative;
    }
    .activity-list {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
        li {
            background-color: $white;
            position: relative;
            border: 1px solid $gray-400;
            @include margin-padding(0  0 10px,  20px 10px 10px);
            font-size: $font-size-15;
            .activity-user {
                height: 50px;
                margin: 0;
                padding: 0;
                width: 50px;
                @include position(absolute,12px,null,null,8px);
            }
            .activity-content {
                background-color: $white;
                position: relative;
                @include margin-padding(0 0 0 60px, 0);
            }
            .time {
                color: $gray-500;
                display: block;
                font-size: $font-size-12;
                line-height: 1.35;
            }
            a.name {
                color: $black;;
            }
            a {
                color: $secondary;
                font-weight: $font-weight-semibold;
                font-size: $font-size-15;
                img{
                    width:50px;
                    height:50px;
                    border-radius: 5px;
                }
            }
        }
    }
}

.account-page {
  background: $white;
}
.login-wrapper {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    z-index: 5;
  }

  &.account-bg {
    background-image: url(../../../../public/assets/img/authentication/reset-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .login-content {
      background: #fffffff2;
      backdrop-filter: blur(10px);
    }
    &.register-bg {
      background-image: url(../../../../public/assets/img/authentication/reset-bg.jpg);
    }
    &.forgot-bg {
      background-image: url(../../../../public/assets/img/authentication/reset-bg.jpg);
    }
    &.reset-bg {
      background-image: url(../../../../public/assets/img/authentication/reset-bg.jpg);
    }
    &.email-veri-wrap {
      // background-image: url(../img/authentication/email-verification-img.jpg);
    }
  }
  .login-content {
    width: 50%;
    height: 100vh;
    @include margin-padding(null, 24px);
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    flex-wrap: wrap;
    @include respond-below(custom991) {
      width: 100%;
    }
    @include respond-below(custom767) {
      @include margin-padding(null, 20px);
    }
    .login-logo {
      display: block;
      max-width: 150px;
      margin: auto;
      margin-bottom: 60px;
      @include respond-below(custom991) {
        margin-bottom: 40px;
      }
      @include respond-below(custom767) {
        margin-bottom: 24px;
      }
    }
    .success-login-logo {
      margin-bottom: 40px;
      @include respond-below(custom767) {
        margin-bottom: 24px;
      }
    }
    .login-user-info {
      .copyright-text {
        margin: 40px 0 0;
        text-align: center;
        @include respond-below(custom767) {
          margin: 0;
        }
        p {
          font-weight: $font-weight-medium;
          color: $gray-900;
          margin-bottom: 0;
        }
      }
      .otp-box {
        margin-bottom: 34px;
        input {
          width: 74px;
          height: 74px;
          background: $white;
          border: 1px solid $light-900;
          border-radius: 8px;
          text-align: center;
          margin-right: 15px;
          font-weight: $font-weight-bold;
          font-size: $font-size-26;
          color: $black;
          outline: none;
          @include respond-below(custom575) {
            width: 50px;
            height: 50px;
          }
        }
      }
      .otp-box input::placeholder {
        color: #74788d;
      }
      .otp-box input:last-child {
        margin-right: 0px;
      }
      .otp-expire {
        border-radius: $border-radius-lg;
        background: $purple-100;
        padding: 3px 0;
        max-width: 150px;
        text-align: center;
        margin: 0 auto 24px;
        p {
          color: $purple;
          font-size: $font-size-12;
          font-weight: $font-weight-normal;
        }
      }
    }
    .login-heading {
      @include margin-padding(0 0 24px, null);
      h4 {
        font-size: $font-size-20;
        @include margin-padding(0 0 10px, null);
      }
      p {
        font-weight: $font-weight-normal;
        color: $gray-400;
        max-width: 410px;
        @include margin-padding(0, null);
      }
      .verfy-mail-content {
        max-width: 400px;
        margin-bottom: 40px;
      }
      .welcome-content {
        margin-bottom: 24px;
      }
      i {
        font-size: $font-size-60;
        color: $success;
        display: inline-block;
        margin-bottom: 24px;
      }
    }
    .form-wrap {
      .btn {
        width: 100%;
        padding: 10px 15px;
        font-size: $font-size-14;
      }
      .alreadyuser {
        margin-bottom: 15px;
        h4 {
          font-size: $font-size-base;
          font-weight: $font-weight-normal;
          color: $sub-title;
          a {
            color: $primary;
            font-weight: $font-weight-semibold;
          }
        }
      }
      .custom-control.custom-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-below(custom575) {
          justify-content: start;
        }
        .custom-control-input {
          width: auto;
          height: auto;
        }
        .custom-control-label {
          margin-bottom: 0;
          margin-left: 8px;
        }
      }
      .forgot-link {
        position: relative;
        color: $primary;
        font-weight: $font-weight-medium;
        &:after {
          content: "";
          height: 2px;
          width: 100%;
          background: $primary;
          @include transform(scale(0));
          @include transition(all 0.3s ease);
          @include position(absolute, null, null, -3px, 0);
        }
        &:hover:after {
          @include transform(scale(1));
        }
        &:hover {
          color: $primary;
        }
      }
    }
    .login-form {
      margin-bottom: 24px;
      h6 {
        font-size: $font-size-14;
        font-weight: $font-weight-normal;
        color: $gray-400;
        margin-bottom: 0;
        @include respond-below(custom575) {
          font-size: $font-size-base;
        }
        a {
          color: $purple;
          font-weight: $font-weight-semibold;
        }
      }
    }
    .form-set-login {
      text-align: center;
      position: relative;
      color: $sub-title;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      margin-bottom: 23px;
      h4 {
        position: relative;
        font-size: $font-size-base;
        color: $text-color;
        font-weight: $font-weight-bold;
        &:after {
          content: "";
          background: $sub-title;
          width: 21px;
          height: 1px;
          @include position(absolute, 10px, null, null, 200px);
          @include respond-below(custom991) {
            height: 0px;
          }
        }
        &:before {
          content: "";
          background: $sub-title;
          width: 21px;
          height: 1px;
          @include position(absolute, 10px, 200px, null, null);
          @include respond-below(custom991) {
            height: 0px;
          }
        }
      }
    }
    .form-set-login.or-text {
      max-width: 365px;
      margin: auto;
    }
    .toggle-password {
      cursor: pointer;
      width: 30px;
      height: 30px;
      color: $gray-900;
      font-size: $font-size-18;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      @include rounded(50%);
      @include transform(translateY(-50%));
      @include position(absolute, 50%, 10px, null, null);
      &:hover {
        color: $primary;
      }
    }
    .toggle-passwords {
      cursor: pointer;
      width: 30px;
      height: 30px;
      color: $gray-900;
      font-size: $font-size-18;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      @include rounded(50%);
      @include transform(translateY(-50%));
      @include position(absolute, 50%, 10px, null, null);
      &:hover {
        color: $primary;
      }
    }
    .toggle-password-new {
      cursor: pointer;
      width: 30px;
      height: 30px;
      color: $gray-900;
      font-size: $font-size-18;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      @include rounded(50%);
      @include transform(translateY(-50%));
      @include position(absolute, 50%, 10px, null, null);
      &:hover {
        color: $primary;
      }
    }
    .pass-group {
      position: relative;
      font-size: $font-size-base;
    }
    .login-social-link {
      margin-top: 24px;
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        li {
          text-align: center;
          margin-right: 10px;
          @include respond-below(custom575) {
            width: 100%;
            margin-bottom: 20px;
          }
          &:last-child {
            padding-right: 0;
            @include respond-below(custom575) {
              padding-right: 0;
            }
          }
          a {
            width: 100%;
            border: 1px solid $light-900;
            background: $white;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            @include rounded(10px);
            @include margin-padding(0 10px 0 0, 15px 53px);
            font-weight: $font-weight-medium;
            @include respond-below(custom1199) {
              @include margin-padding(0 10px 0 0, 15px 50px);
            }
            @include respond-below(custom991) {
              @include margin-padding(0 10px 0 0, 15px 46px);
            }
            &:hover {
              border-color: $light-hover;
            }
            img {
              width: 24px;
              height: 24px;
            }
          }
          .facebook-logo {
            background: $indigo;
            &:hover {
              background: $indigo-hover;
            }
          }
          .apple-logo {
            background: $dark;
            &:hover {
              background: $gray-800;
            }
          }
        }
      }
    }
  }
  .login-content.user-login {
    width: 100%;
    padding: 0;
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    height: auto;
    margin: 50px 0;
    @include respond-below(custom991) {
      margin: 30px 0;
    }
    .login-user-info {
      background: $white;
      box-shadow: $box-shadow;
      margin: 0;
      padding: 40px;
      border-radius: 10px;
      border: 1px solid $light-900;
      width: 100%;
      min-width: 490px;
      @include respond-below(custom991) {
        padding: 30px;
      }
      @include respond-below(custom767) {
        padding: 24px;
        min-width: inherit;
      }
      @include respond-below(custom575) {
        padding: 24px 24px 4px;
      }
    }
    .login-user-inner {
      @include respond-below(custom767) {
        padding-bottom: 24px;
      }
    }
    .login-logo {
      margin-bottom: 50px;
      @include respond-below(custom991) {
        margin-bottom: 40px;
      }
      @include respond-below(custom767) {
        margin-bottom: 24px;
      }
    }
  }
  .login-img {
    width: 50%;
    background: $danger-100;
    @include respond-below(custom991) {
      display: none;
    }
    img {
      width: 100%;
      height: 100vh;
    }
  }
  .login-user-info {
    .login-logo.login-info {
      display: block;
      max-width: 150px;
      margin: auto;
    }
  }
  .login-logo {
    max-width: 150px;
    margin: auto;
  }
}
.form-wrap {
  .form-wrap-icon {
    position: relative;
    i {
      font-size: $font-size-18;
      @include position(absolute, 50%, 15px, null, null);
      transform: translateY(-50%);
    }
  }
}
.form-wrap-checkbox {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 20px;
  .check {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    color: $gray-900;
    margin-bottom: 0;
    input {
      display: none;
      &:checked + .box {
        background: $secondary;
        &:after {
          top: 0;
        }
      }
    }
    .box {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 8px;
      transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
      background: $light-200;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      border: 1px solid $light-900;
      border-radius: $border-radius-lg;
      &:after {
        width: 12px;
        height: 6px;
        content: "";
        position: absolute;
        border-left: 2px solid;
        border-bottom: 2px solid;
        border-color: $white;
        transform: rotate(-45deg) translate3d(0, 0, 0);
        transform-origin: center center;
        transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
        left: 0;
        right: 0;
        top: 200%;
        bottom: 3px;
        margin: auto;
      }
    }
  }
}
.hover-a {
  position: relative;
  color: $purple;
  &:after {
    content: "";
    height: 2px;
    width: 100%;
    background: $purple;
    @include transform(scale(0));
    @include transition(all 0.3s ease);
    @include position(absolute, null, null, -3px, 0);
  }
  &:hover:after {
    @include transform(scale(1));
  }
  &:hover {
    color: $purple;
  }
}
.login-wrapper.login-new {
  overflow: auto;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
  .login-shapes {
    position: relative;
    @include respond-below(custom991) {
      display: none;
    }
    .login-right-shape {
      position: fixed;
      top: 0;
      left: 0;
    }
    .login-left-shape {
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }
  .login-content.user-login {
    position: relative;
    .login-userset {
      form .otp-box {
        margin-bottom: 34px;
        input {
          width: 74px;
          height: 74px;
          background: $white;
          border: 1px solid #e1e1e1;
          border-radius: 8px;
          text-align: center;
          margin-right: 15px;
          font-weight: $font-weight-bold;
          font-size: $font-size-26;
          color: $black;
          outline: none;
          @include respond-below(custom575) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  .copyright-text {
    margin: 40px 0;
    text-align: center;
    @include respond-below(custom767) {
      margin: 0 0 30px;
    }
    p {
      font-weight: $font-weight-medium;
      color: $gray-900;
      margin-bottom: 0;
    }
  }
  .lock-screen-list {
    text-align: center;
    .nav {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      li {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-weight: $font-weight-medium;
        }
      }
    }
    .copyright-text {
      margin: 20px 0 40px;
      @include respond-below(custom767) {
        margin: 20px 0 30px;
      }
    }
    .language-dropdown {
      .dropdown-toggle {
        border: 0;
        padding: 0;
        border-radius: $border-radius-lg;
        &:after {
          border-top: 0;
          border-left: 0;
          border-bottom: 2px solid $gray-400;
          border-right: 2px solid $gray-400;
          content: "";
          display: inline-block;
          pointer-events: none;
          width: 8px;
          height: 8px;
          vertical-align: 2px;
          @include transform-origin(66% 66%);
          @include transition(all 0.2s ease);
          @include transform(rotate(45deg));
        }
        &[aria-expanded="true"]:after {
          @include transform(rotate(-135deg));
        }
      }
      .dropdown-menu {
        padding: 15px;
        background: $white;
        box-shadow: $box-shadow-lg;
        border: 1px solid $secondary-100;
        .dropdown-item {
          padding: 5px 10px;
          background: $white;
          border-radius: $border-radius-lg;
          display: block;
          &:hover {
            background: $light-300;
          }
        }
      }
    }
  }
}
.copyright-text {
  margin: 40px 0;
  text-align: center;
  @include respond-below(custom767) {
    margin: 0;
  }
  p {
    font-weight: $font-weight-medium;
    color: $gray-900;
    margin-bottom: 0;
  }
}
.lock-screen-profile {
  img {
    width: 100px;
    height: 100px;
    border-radius: $rounded;
    border: 2px solid #919eab4d;
  }
  h6 {
    font-size: $font-size-18;
    font-weight: $font-weight-semibold;
    color: $gray-900;
    margin-top: 24px;
    margin-bottom: 0;
  }
}

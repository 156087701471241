.page-header {
  margin-bottom: 20px;
  .page-title {
    font-weight: $font-weight-semibold;
    margin-bottom: 10px;
    @include respond-below(custom991) {
      font-size: $font-size-18;
    }
    @include respond-below(custom767) {
      margin-bottom: 14px;
    }
    .count-title {
      background: #f3edff;
      border-radius: 5px;
      @include margin-padding(0 0 0 8px, 7px);
      color: $purple;
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
    }
  }
  .title-head {
    @include respond-below(custom991) {
      margin-top: 0;
    }
  }
  .head-icons {
    margin-bottom: 10px;
    a {
      width: 32px;
      height: 32px;
      background: $white;
      color: $gray-900;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      border: 1px solid $light-900;
      border-radius: 5px;
      margin-right: 8px;
      &.active,
      &:hover {
        background: $green;
        color: $white;
      }
      &:last-child {
        margin-right: 0;
      }
      @include respond-below(custom991) {
        margin-right: 4px !important;
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
  }
  .form-sort {
    margin-top: 0;
  }
}
.card {
  &.main-card {
    margin-bottom: 24px;
    background-color: $white;
    transition: all 0.5s ease-in-out;
    position: relative;
    border-radius: 5px;
    border: 0;
    box-shadow: none;
    .card-body {
      padding: 15px;
    }
  }
}
.set-star {
  i {
    color: $light-900;
    &.filled {
      color: $orange;
    }
  }
}
.rating-select {
  &.filled {
    i {
      color: $orange;
    }
  }
}
.search-section {
  border-bottom: 1px solid $light-900;
  margin-bottom: 20px;
  .form-control {
    max-width: 320px;
    min-height: 40px;
    @include respond-below(custom575) {
      width: 100%;
      max-width: unset;
    }
  }
}
.icon-form {
  position: relative;
  .form-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: $gray-900;
  }
  .form-control {
    @include margin-padding(null, 8px 15px 8px 34px);
  }
}
.icon-form-end {
  position: relative;
  .form-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: $gray-900;
  }
  .form-control {
    @include margin-padding(null, 8px 34px 8px 15px);
  }
}
.filter-list {
  & > ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: end;
    margin-bottom: 5px;
    & > li {
      margin: 0 0 10px 8px;
      .btn {
        @include respond-below(custom575) {
          width: 100%;
        }
        i {
          margin-right: 8px;
        }
      }
      .btn-purple-light {
        i {
          margin-right: 8px;
        }
        &:hover,
        &.active,
        &:focus,
        &.show {
          background-color: $purple-100;
          border-color: $purple-100;
          color: $purple;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      .view-icons {
        float: unset;
      }
      @include respond-below(custom575) {
        margin-left: 0;
      }
    }
    @include respond-below(custom1199) {
      justify-content: start;
    }
    @include respond-below(custom575) {
      display: block;
    }
  }
}
.view-icons {
  border: 1px solid $light-900;
  border-radius: 5px;
  @include margin-padding(null, 2px);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  a {
    width: 34px;
    height: 36px;
    background: $white;
    color: $gray-900;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
    &.active,
    &:hover {
      background: $green;
      color: $white;
    }
  }
}
.export-list {
  & > ul > li {
    display: inline-block;
    margin: 0 8px 20px 0;
    &:last-child {
      margin-right: 0;
    }
    .btn {
      i {
        margin-right: 8px;
      }
    }
  }
}
.export-dropdwon {
  & > a {
    border: 1px solid $light-900;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #dbdbdb40;
    font-weight: $font-weight-medium;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @include margin-padding(null, 9px 15px);
    i {
      margin-right: 8px;
    }
    &[aria-expanded="true"] {
      &::after {
        @include transition(all 0.2s ease);
        @include transform(rotate(180deg));
      }
    }
    &::after {
      font-size: $font-size-14;
      margin-left: 8px;
    }
    &:hover {
      color: $title-color;
    }
    &.show {
      border: 1px solid #0092e4;
    }
  }
  .dropdown-menu {
    li {
      button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        @include margin-padding(null, 5px);
        border-radius: 5px;
        i {
          margin-right: 8px;
        }
        &:hover {
          background: $light-300;
          color: $title-color;
        }
      }
    }
  }
}
.dropdown-menu {
  border: 1px solid $light-900;
  padding: 15px;
  box-shadow: $box-shadow-sm;
}
.manage-dropdwon {
  .dropdown-menu {
    border: 1px solid $light-900;
    padding: 15px;
    max-width: 313px;
    box-shadow: $box-shadow-sm;
    h4 {
      font-weight: $font-weight-semibold;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
    }
    ul {
      border-top: 1px solid $light-900;
      padding-top: 20px;
      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: space-between;
        margin-bottom: 15px;
        p {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-weight: $font-weight-medium;
          margin: 0;
          i {
            margin-right: 8px;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    @include respond-below(custom767) {
      max-width: 270px;
    }
  }
}
.status-toggle {
  &.small-toggle-btn {
    float: unset;
    .label-text {
      color: $text-color;
      font-size: $font-size-14;
      font-weight: $font-weight-normal;
    }
    .checktoggle {
      width: 24px;
      height: 12px;
      &:after {
        width: 8px;
        height: 8px;
      }
    }
    .check {
      &:checked {
        & + .checktoggle {
          &:after {
            right: 0;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
  .check {
    display: block;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    margin: 0;
    padding: 0;
    &:checked + .checktoggle:after {
      left: 100%;
      transform: translate(calc(-100% - 3px), -50%);
      right: 0;
      width: 10px;
      height: 10px;
    }
    &:checked + .checktoggle {
      background: #1abe17;
    }
  }
  .checktoggle {
    background: #cdcdcd;
    cursor: pointer;
    display: block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
    border-radius: 12px;
    width: 30px;
    height: 14px;
    &:after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #fff;
      -webkit-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      transition: all 0.2s ease;
      transform: translate(3px, -50%);
      -webkit-transform: translate(3px, -50%);
      -ms-transform: translate(3px, -50%);
      position: absolute;
      top: 50%;
      left: 0;
      border-radius: 50%;
    }
  }
}
.badge-status {
  border-radius: 5px;
  font-size: $font-size-12;
  padding: 4px 8px;
  min-width: 64px;
  color: $white;
}
.badge-tag {
  border-radius: 5px;
  font-size: $font-size-12;
  padding: 4px 4px;
  font-weight: $font-weight-normal;
  &.badge-success-light {
    background: $success-200;
    color: $success;
  }
  &.badge-warning-light {
    background: $warning-100;
    color: $warning;
  }
  &.badge-purple-light {
    background: $purple-100;
    color: $purple;
  }
  &.badge-danger-light {
    background: $danger-100;
    color: $danger;
  }
}
.custom-table {
  table {
    td {
      ul {
        &.social-links {
          li {
            a {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 5px;
              color: $text-color;
              &:hover {
                background: $light-900;
                color: $text-color;
              }
            }
          }
        }
      }
    }
  }
}
.table-action {
  .action-icon {
    width: 33px;
    height: 33px;
    background: $light-300;
    border: 1px solid $light-900;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $gray-900;
    &:hover {
      color: $gray-900;
      i {
        color: $gray-900;
      }
    }
  }
  .dropdown-menu {
    button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      @include margin-padding(null, 5px);
      border-radius: 5px;
      color: $title-color;
      white-space: nowrap;
      i {
        margin-right: 8px;
      }
      &:hover {
        background: $light-300;
        color: $title-color;
      }
    }
  }
}
.daterangepicker {
  font-family: $font-family-primary !important;
  .calendar-table {
    th {
      color: $gray-900 !important;
    }
    td {
      color: $gray-900 !important;
      &.in-range {
        background-color: $secondary-100 !important;
      }
    }
  }
}

.daterangepicker td.active,
.daterangepicker td.active.end-date,
.daterangepicker td.active:hover {
  background: $secondary !important;
  color: white !important;
  font-weight: bold;
}
.daterangepicker .ranges li.active {
  background-color: $secondary !important;
}

.filter-section {
  &.filter-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    flex-wrap: wrap;
    @include respond-below(custom575) {
      display: block;
    }
  }
}
.sortby-list {
  .form-control {
    max-width: 212px;
  }
  & > ul {
    margin-bottom: 5px;
    & > li {
      display: inline-block;
      margin: 0 8px 15px 0;
      &:last-child {
        margin-right: 0;
      }
      .form-wrap {
        margin-bottom: 0;
      }
    }
  }
}
.sort-dropdown.drop-down {
  .dropdown-menu li a {
    white-space: nowrap;
  }
}
.drop-down {
  position: relative;
  & > a {
    border: 1px solid $light-900;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #dbdbdb40;
    font-weight: $font-weight-medium;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @include margin-padding(null, 9px 15px);
    i {
      margin-right: 8px;
    }
    &:hover {
      color: $title-color;
    }
    &.show {
      border: 1px solid #0092e4;
    }
    &::after {
      font-size: $font-size-14;
      margin-left: 8px;
    }
    &[aria-expanded="true"] {
      &::after {
        @include transition(all 0.2s ease);
        @include transform(rotate(180deg));
      }
    }
  }
  .dropdown-menu {
    li {
      button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        @include margin-padding(null, 5px);
        border-radius: 5px;
        i {
          margin-right: 8px;
        }
        &:hover {
          background: $light-300;
          color: $title-color;
        }
      }
    }
  }
}
.form-sorts {
  position: relative;
  & > a {
    border: 1px solid $light-900;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #dbdbdb40;
    font-weight: $font-weight-medium;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @include margin-padding(null, 9px 21px);
    @include respond-below(custom991) {
      @include margin-padding(null, 9px 12px);
    }
    i {
      margin-right: 8px;
    }
    &:hover {
      color: $title-color;
    }
    &.show {
      border: 1px solid #0092e4;
    }
    @include respond-below(custom575) {
      width: 100%;
    }
  }
  .filter-dropdown-menu {
    padding: 24px;
    border-radius: 5px;
    background: $white;
    border: 1px solid $light-900;
    box-shadow: 0px 4px 24px 0px #bcbcbc40;
    position: absolute;
    z-index: 1;
    min-width: 400px;
    right: 0;
    top: 40px;
    @include respond-below(custom767) {
      min-width: 270px;
    }
    @include respond-below(custom575) {
      right: unset;
      left: 0;
    }
  }
  &.table-filter-show {
    .filter-dropdown-menu {
      display: block;
      opacity: 1;
    }
  }
  .filter-set-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 14px;
    h4 {
      margin-bottom: 10px;
      i {
        margin-right: 8px;
      }
      @include respond-below(custom991) {
        font-size: $font-size-16;
      }
    }
    a {
      color: $purple;
      margin-bottom: 10px;
      &:hover {
        color: $primary;
      }
    }
  }
  .filter-set-content {
    margin-bottom: 24px;
    .filter-set-content-head {
      a {
        display: flex;
        align-items: center;
        color: $gray-900;
        font-weight: $font-weight-semibold;
        position: relative;
        padding-left: 16px;
        &::before {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          @include position(absolute, 50%, null, null, 0);
          @include transform(translateY(-50%));
          font-size: $font-size-14;
        }
        &:not(.collapsed) {
          padding-left: 20px;
          &::before {
            content: "\f078";
          }
        }
      }
    }
  }
  .filter-reset-btns {
    button {
      padding: 6px 10px;
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
      border-radius: 5px;
      width: 100%;
      @include respond-below(custom575) {
        padding: 10px 30px;
      }
    }
    .btn-light {
      background: $light-400;
      border: 1px solid $light-900;
    }
  }
}
.header-set {
  border-bottom: 1px solid $light-900;
  margin-bottom: 24px;
  .select2-container--default {
    margin-bottom: 15px;
    .select2-selection--single {
      border-color: $light-900;
      .select2-selection__rendered {
        color: $title-color;
      }
    }
  }
  .dropdown-toggle {
    border: 1px solid $light-900;
    border-radius: 5px;
    @include margin-padding(null, 12px 15px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    box-shadow: 0px 4px 4px 0px #dbdbdb40;
  }
}
.radio-btn-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 14px;
  .radio-btn {
    margin: 0 30px 10px 0;
    &:last-child {
      margin-right: 0;
    }
  }
}
.filter-set-contents {
  .filter-content-list {
    background: $light-200;
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;
    .form-wrap {
      margin-bottom: 15px;
      .form-control {
        max-width: 100%;
      }
    }
    ul {
      max-height: 150px;
      overflow-y: auto;
      padding: 1px 0;
      &::-webkit-scrollbar {
        width: 3px;
        background: $primary;
        height: 1px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(231, 231, 231);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 4px;
      }
      li {
        position: relative;
        display: flex;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .checkboxs .checkmarks {
          top: 1px;
        }
        .rating {
          margin-left: 0;
          i {
            color: $light-900;
            &.filled {
              color: $warning;
            }
          }
          span {
            margin-left: 5px;
          }
        }
        .collapse-inside-text {
          margin-left: 0;
          h5 {
            margin-bottom: 0;
            font-size: $font-size-14;
            font-weight: $font-weight-normal;
          }
        }
      }
    }
  }
}
.filter-filelds {
  .filter-row .btn {
    background: $secondary;
    color: $white;
    border-color: $secondary;
  }
}
.checkboxs {
  display: block;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 25px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
.checkboxs input ~ .checkmarks {
  border: 1px solid #9b9b9b;
  border-radius: 5px;
}
.checkboxs input:checked ~ .checkmarks {
  background-color: $purple;
  border-color: $purple;
}
.checkboxs input:checked ~ .checkmarks:after {
  display: block;
}
.checkboxs .checkmarks {
  height: 16px;
  width: 16px;
  @include position(absolute, 0, null, null, 0);
}
.checkboxs .checkmarks:after {
  content: "";
  position: absolute;
  display: none;
}
.checkboxs .checkmarks:after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  color: $white;
  font-weight: $font-weight-semibold;
  font-size: 10px;
}
.status-radio:checked,
.status-radio:not(:checked) {
  position: absolute;
  left: -9999px;
}
.status-radio:checked + label,
.status-radio:not(:checked) + label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: $gray-900;
}
.status-radio:checked + label:before,
.status-radio:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid $light-900;
  border-radius: 100%;
  background: $white;
}
.status-radio:checked + label:before {
  background: $indigo;
  border-color: $indigo;
}
.status-radio[type="radio"]:checked + label:after,
.status-radio[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: $white;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.text-blue {
  color: $purple !important;
}
.text-blue-light {
  color: $indigo !important;
}
.btn-purple-light {
  background-color: $purple-100;
  border-color: $purple-100;
  color: $purple;
  font-size: $font-size-14;
  font-weight: $font-weight-medium;
  border-radius: 5px;
  @include margin-padding(null, 9px 20px);
  @include transition(all 0.2s ease);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  @include respond-below(custom991) {
    @include margin-padding(null, 9px 14px);
  }
}
$green-hover: darken($green, 10%);
.btn-green {
  background: $green;
  border: 1px solid $green;
  color: $white;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  border-radius: $border-radius-lg;
  @include margin-padding(null, 9px 19px);
  @include transition(all 0.2s ease);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  &:hover,
  &:active {
    background: $green-hover !important;
    border-color: $green-hover !important;
    color: $white;
  }
  @include respond-below(custom991) {
    min-width: auto;
  }
}
.modal-btn {
  .btn {
    @include margin-padding(0 10px 0 0, 7px 15px);
    &:last-child {
      margin-right: 0;
    }
  }
}
.custom-modal {
  .modal-header {
    .btn-close {
      @include margin-padding(0, 0);
      background: $white;
      border: 1px solid $light-900;
      border-radius: 50%;
      color: $gray-400;
      font-weight: $font-weight-normal;
      font-size: $font-size-14;
      box-shadow: 0px 4px 4px 0px #dbdbdb40;
      height: 26px;
      width: 26px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
      &:hover {
        border: 1px solid $primary;
        background: $primary;
        color: $white;
        -webkit-transition: all 0.7s;
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
        transition: all 0.7s;
        -webkit-transform: rotateZ(360deg);
        -moz-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
      }
    }
  }
}
.toggle-body {
}
.toggle-height {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.toggle-popup,
.toggle-popup1,
.toggle-popup2 {
  position: fixed;
  top: 0;
  width: 700px;
  right: -700px;
  height: 100vh;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
  background: rgba(50, 48, 56, 0.4);
  z-index: 1002;
  &.sidebar-popup {
    right: 0;
    opacity: 0;
    width: 100%;
    transition: ease all 0.4s;
    -webkit-transition: ease all 0.4s;
    -ms-transition: ease all 0.4s;
  }

  .medium-layout {
    max-width: 500px !important;
  }

  .large-layout {
    max-width: 700px !important;
  }

  .sidebar-layout {
    background-color: #fff;
    width: 100%;
    position: absolute;
    right: 0;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .sidebar-header {
      padding: 20px;
      border-bottom: 1px solid #eff2f5;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      h4 {
        margin: 0;
        font-weight: $font-weight-semibold;
      }
      a {
        color: #ff0000;
        font-size: 24px;
      }
      .toggle-btn {
        background: $white;
        border: 1px solid $light-900;
        border-radius: 50%;
        color: $gray-400;
        font-weight: $font-weight-normal;
        font-size: $font-size-14;
        box-shadow: 0px 4px 4px 0px #dbdbdb40;
        height: 26px;
        width: 26px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -webkit-transition: all 0.7s;
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
        transition: all 0.7s;
        &:hover {
          border: 1px solid $primary;
          background: $primary;
          color: $white;
          -webkit-transition: all 0.7s;
          -moz-transition: all 0.7s;
          -o-transition: all 0.7s;
          transition: all 0.7s;
          -webkit-transform: rotateZ(360deg);
          -moz-transform: rotateZ(360deg);
          transform: rotateZ(360deg);
        }
      }
    }
    .toggle-body {
      padding: 20px 20px 0;
      .select2-container--default {
        .select2-selection--single {
          height: 42px;
          .select2-selection__rendered {
            height: 42px;
            line-height: 42px;
          }
          .select2-selection__arrow {
            height: 42px;
          }
        }
        .select2-selection--multiple {
          height: 42px;
          overflow-y: auto;
          .select2-selection__choice {
            margin-top: 4px;
          }
        }
      }
      .submit-button {
        padding-bottom: 20px;
      }
    }
    @include respond-below(custom767) {
      max-width: 500px;
    }
  }
}

.profile-pic-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
  .profile-pic {
    cursor: pointer;
    border: 2px dashed #a2a2a2;
    margin: 0 15px 10px 0;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: relative;

    &:hover {
      background-color: #ececec;
    }

    span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    img {
      width: 70px;
      height: 70px;
      border-radius: 5px;
    }
    .close-img {
      background: $danger;
      color: $white;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      border-radius: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      @include position(absolute, 10px, 10px, null, null);
    }
  }
  .upload-content {
    p {
      margin-bottom: 10px;
    }
  }
  .upload-btn {
    background: $purple-100;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
    display: inline-block;
    cursor: pointer;
    &:hover {
      background-color: $purple-200;
    }
    span {
      @include margin-padding(null, 9px 12px);
      cursor: pointer;
      color: $purple;
      display: inline-block;
      i {
        margin-right: 10px;
      }
    }
    input {
      display: none;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      cursor: pointer;
    }
  }
}
.modal {
  z-index: 1050 !important;
}
.modal-backdrop {
  z-index: 1001 !important;
}
.bootstrap-tagsinput {
  border-color: $light-300;
  box-shadow: 0px 4px 4px 0px #dbdbdb40;
  border-radius: 5px;
  font-size: $font-size-14;
  color: $text-color;
  min-height: 42px;
  line-height: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 42px;
  overflow-y: auto;
  .tag {
    background: $light-300;
    color: $text-color;
    padding: 2px 5px;
    line-height: normal;
    border-radius: 5px;
    font-size: $font-size-14;
  }
}
.user-accordion-item {
  background: $white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  box-shadow: 0px 4px 74px 0px #d0d0d040;
  margin-bottom: 15px;
  .accordion-wrap {
    @include margin-padding(null, 10px 25px 10px 10px);
    font-weight: $font-weight-medium;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    span {
      width: 36px;
      height: 36px;
      border-radius: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      margin-right: 8px;
    }
    &:hover {
      color: $gray-900;
    }
    &::before {
      content: "\f054";
      font-family: "Font Awesome 5 Free";
      @include position(absolute, 50%, 10px, null, null);
      @include transform(translateY(-50%));
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
    }
    &:not(.collapsed) {
      &::before {
        content: "\f078";
      }
      span {
        background: $primary;
        color: $white;
      }
    }
  }
  .content-collapse {
    border-top: 1px solid $light-900;
    @include margin-padding(5px 10px 10px, 15px 0 0);
  }
  .accordion-collapse {
    .form-wrap {
      margin-bottom: 24px;
      &.mb-wrap {
        margin-bottom: 0;
        @include respond-below(custom767) {
          margin-bottom: 24px;
        }
      }
    }
  }
}
.submit-button {
  .btn {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    border-radius: $border-radius-lg;
    @include margin-padding(0 10px 0 0, 7px 15px);
    &:last-child {
      margin-right: 0;
    }
  }
}
.radio-wrap {
  .col-form-label {
    padding-bottom: 12px;
  }
  .radio-btn {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.success-message {
  h3 {
    margin-bottom: 10px;
    font-size: $font-size-20;
  }
  .del-info {
    max-width: 300px;
    @include margin-padding(0 auto, null);
  }
  p {
    font-size: $font-size-16;
    margin-bottom: 0;
    @include respond-below(custom767) {
      font-size: $font-size-14;
    }
  }
  .modal-btn {
    margin-top: 40px;
  }
}
.success-popup-icon {
  background: $danger-100;
  color: $danger;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  font-size: $font-size-42;
  &.bg-light-blue {
    background: $purple-100;
    color: $purple;
  }
}
.access-wrap {
  border-radius: 5px;
  @include margin-padding(0 0 20px, 10px);
  box-shadow: $box-shadow-sm;
  li {
    @include margin-padding(0 0 5px, 0);
    &.checkbox-checked {
      label {
        background: $light-300;
      }
    }
    label {
      width: 100%;
      position: relative;
      @include margin-padding(null, 5px);
      background: transparent;
      &.checkboxs {
        .checkmarks {
          right: 10px;
          left: unset;
          top: 50%;
          transform: translatey(-50%);
        }
        input:checked ~ .checkmark {
          background: $primary;
          border-color: $primary;
        }
      }
      .people-profile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 10px;
        }
        a {
          color: $text-color;
          font-size: $font-size-14;
        }
      }
    }
    &:hover {
      background-color: $light-300;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.contact-grid {
  box-shadow: $box-shadow;
  border: 1px solid $light-900;
  border-radius: 5px;
  padding: 24px;
  background: $white;
  color: $text-color;
  margin-bottom: 24px;
  .grid-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .users-profile {
      display: flex;
      align-items: center;
      .avatar {
        width: 42px;
        height: 42px;
        margin-right: 8px;
        img {
          border-radius: 5px;
        }
      }
      .name-user {
        margin-bottom: 0;
        h6 {
          font-size: $font-size-14;
          font-weight: $font-weight-medium;
          a {
            color: $title-color;
          }
        }
      }
    }
    .dropdown {
      .dropdown-toggle {
        &::after {
          display: none;
        }
        i {
          color: $secondary-900;
        }
      }
    }
  }
  .grid-body {
    .address-info {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: 20px 0;
      p {
        display: inline-flex;
        align-items: center;
        line-height: normal;
        margin-bottom: 10px;
        color: $text-color;
        i {
          color: $title-color;
          margin-right: 8px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .grid-badges {
      span {
        + span {
          margin-left: 5px;
        }
      }
    }
  }
  .grid-footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $light-900;
    .social-links {
      li {
        a {
          color: $text-color;
          width: 26px;
          height: 26px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: 0.5s all;
          &:hover {
            background: $danger-100;
            color: $primary;
            transition: 0.5s all;
          }
        }
      }
    }
    .star-user {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .set-star {
        margin-right: 10px;
      }
    }
  }
}
.load-btn {
  .btn {
    i {
      margin-left: 4px;
      animation: spin 3000ms linear infinite;
    }
  }
}
@keyframes spin {
  to {
    @include transform(rotate(360deg));
  }
}
.table-avatar {
  .company-img {
    flex-shrink: 0;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 1px solid $light-900;
    border-radius: 5px;
  }
}
.img-select {
  position: relative;
  .select-box-img {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 9999;
  }
  .select2-selection__rendered {
    padding-left: 40px !important;
  }
}
.company-profile {
  .company-img {
    flex-shrink: 0;
    margin-right: 10px;
    width: 60px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 1px solid $light-900;
    border-radius: 5px;
  }
}
.users-group {
  ul {
    display: flex;
    align-items: center;
    li {
      a {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $white;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: normal;
        margin-left: -11px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        &.total-group-member {
          background: $primary;
          color: $white;
          font-size: $font-size-10;
        }
        &:hover {
          position: relative;
        }
      }
      &:first-child {
        a {
          margin-left: 0;
        }
      }
    }
  }
}
.add-new {
  color: $danger;
}
.contact-head {
  margin-bottom: 5px;
  .contact-breadcrumb {
    margin-bottom: 5px;
    li {
      color: $text-color;
      position: relative;
      display: inline-block;
      @include margin-padding(0 2px 5px 0, 0 0 0 10px);
      a {
        color: $text-color;
        i {
          color: $primary;
          margin-right: 8px;
        }
        &:hover {
          color: $primary;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        padding-left: 0;
        &::before {
          content: none;
        }
      }
      &::before {
        content: "/";
        @include position(absolute, 0, null, null, 0);
      }
      &.before-none {
        padding-left: 5px;
        &::before {
          content: none;
        }
      }
      .btn {
        i {
          color: $white;
        }
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
  .contact-pagination {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-bottom: 10px;
    p {
      @include margin-padding(0 15px 0 0, null);
    }
    ul {
      li {
        display: inline-block;
        margin-right: 5px;
        a {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background: $white;
          color: $title-color;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          justify-content: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          &:hover {
            background: $primary;
            color: $white;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.contact-wrap {
  box-shadow: 0px 4px 54px 0px #e0e0e040;
  background: $white;
  border-radius: 5px;
  @include margin-padding(0 0 24px, 24px 24px 14px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  .contact-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    .avatar {
      margin-right: 15px;
      flex-shrink: 0;
      img {
        flex-shrink: 0;
      }
      @include respond-below(custom768) {
        width: 60px;
        height: 60px;
      }
      &.company-avatar {
        border: 1px solid $light-900;
        width: 80px;
        height: 80px;
        img {
          width: 48px;
          height: auto;
        }
        @include respond-below(custom768) {
          width: 60px;
          height: 60px;
        }
        .text-icon {
          color: $sub-title;
          font-size: $font-size-18;
          font-weight: $font-weight-medium;
        }
      }
    }
    h5 {
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 10px;
    }
    .status {
      border: 2px solid $white;
      height: 10px;
      width: 10px;
      background-color: $success;
      @include margin-padding(0, null);
      @include position(absolute, null, -1px, -1px, null);
      @include rounded(50%);
      display: inline-block;
    }
    .badge-rate {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      .badge {
        // font-size: 14px;
        // font-weight: $font-weight-normal;
        // border-radius: 5px;
        // padding: 6px 6px;
        // color: $text-color;
        // display: inline-block;
        // margin-right: 15px;
        // background: $light-600;
        i {
          margin-right: 5px;
        }
      }
      p {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        margin-bottom: 0;
        i {
          color: $orange;
          margin-right: 5px;
        }
      }
    }
    margin-bottom: 10px;
  }
  @include respond-below(custom767) {
    @include margin-padding(0 0 24px, 15px 15px 5px);
  }
}
.contacts-action {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  & > a {
    margin: 0 8px 10px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  .badge {
    @include margin-padding(0 8px 10px 0, 7px 6px);
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    &.badge-light {
      background: $light-600;
      color: $gray-400;
    }
    i {
      margin-right: 8px;
    }
  }
  .action-drops {
    margin-bottom: 10px;
    .dropdown-toggle {
      font-weight: $font-weight-semibold;
      color: $white;
      display: inline-block;
      span {
        display: inline-block;
        border-radius: 5px;
        @include margin-padding(null, 4px 10px);
        color: $white;
      }
    }
  }
  .dropdown-toggle {
    margin-right: 0;
    color: $secondary-900;
    &::after {
      content: none;
    }
  }
  .btn {
    border-radius: 5px;
    padding: 6px 13px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    font-size: $font-size-15;
    font-weight: $font-weight-medium;
    i {
      margin-right: 5px;
    }
  }
  .btn-icon {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background: $light-300;
    border: 1px solid $light-300;
    color: $title-color;
    &:hover {
      background: $primary;
      color: $white;
    }
    &.rating {
      border-radius: 50%;
      background: $white;
      border: 1px solid $light-900;
      color: $orange;
    }
  }
  .act-dropdown {
    & > button {
      width: 34px;
      height: 34px;
      border-radius: 5px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      background: $white;
      border: 1px solid $light-900;
      &:hover {
        background: $light-900;
        color: $gray-900;
      }
    }
    .dropdown-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      @include margin-padding(null, 5px);
      border-radius: 5px;
      color: $title-color;
      i {
        margin-right: 8px;
      }
      &:hover {
        background: $light-300;
        color: $title-color;
      }
    }
  }
}
.contact-sidebar {
  box-shadow: 0px 4px 54px 0px #e0e0e040;
  background: $white;
  @include margin-padding(0 0 24px, 15px);
  border-radius: 5px;
  h6 {
    font-weight: $font-weight-semibold;
    margin-bottom: 15px;
  }
  .com-add {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-bottom: 15px;
  }
  ul {
    @include margin-padding(0 0 20px, 0 0 20px);
    border-bottom: 1px solid $light-900;
    &:last-child {
      @include margin-padding(0, 0);
      border: 0;
    }
    li {
      font-size: $font-size-14;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .project-mem {
    li {
      margin-bottom: 0;
      &.more-set {
        a {
          color: $gray-400;
        }
      }
    }
  }
  .social-info {
    padding-bottom: 15px;
    li {
      @include margin-padding(0 10px 5px 0, null);
    }
  }
  .tag-info {
    margin-bottom: 15px;
    li {
      margin-bottom: 5px;
    }
  }
}
.basic-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-size: $font-size-14;
  span {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background: $light-300;
    color: $title-color;
    margin-right: 10px;
    flex-shrink: 0;
  }
}
.set-info {
  li {
    a {
      color: $gray-400;
      &:hover {
        color: $primary;
      }
    }
    i {
      margin-right: 5px;
    }
  }
}
.other-info {
  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .other-title {
      min-width: 110px;
      display: inline-block;
    }
    .avatar-xs {
      width: 16px;
      height: 16px;
      border-radius: 5px;
    }
  }
}
.con-sidebar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
  a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    color: $purple;
    &:hover {
      color: $primary;
    }
  }
}
.company-info {
  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    span {
      width: 52px;
      height: 52px;
      border-radius: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      border: 1px solid $light-900;
      margin-right: 10px;
    }
    h6 {
      font-size: $font-size-14;
      font-weight: $font-weight-medium;
      margin-bottom: 2px;
    }
    p {
      font-size: $font-size-14;
      margin-bottom: 0;
    }
  }
  &.com-info {
    li {
      span {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 50%;
      }
      p {
        i {
          margin-right: 5px;
          color: $warning;
        }
      }
    }
  }
}
.deals-info {
  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    img {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      margin-right: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.social-info {
  li {
    display: inline-block;
    a {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      background: $white;
      color: $gray-900;
      &:hover {
        background: $gray-900;
        color: $white;
      }
    }
  }
}
.tag-info {
  li {
    margin-right: 5px;
    display: inline-block;
    a {
      font-size: $font-size-12;
      font-weight: $font-weight-medium;
      border-radius: 5px;
      @include margin-padding(null, 5px 10px);
      display: inline-block;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.projects-info {
  li {
    margin-right: 10px;
    display: inline-block;
    a {
      font-size: $font-size-12;
      font-weight: $font-weight-semibold;
      border-radius: 5px;
      @include margin-padding(null, 6px 6px);
      display: inline-block;
      color: $gray-400;
      &:hover {
        color: $title-color;
      }
      &.badge-light {
        background: $light-300;
        color: $gray-400;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.priority-info {
  .dropdown {
    .dropdown-toggle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      border: 1px solid $light-900;
      box-shadow: 0px 4px 4px 0px #dbdbdb40;
      border-radius: 5px;
      @include margin-padding(null, 5px 10px);
      font-weight: $font-weight-medium;
      &[aria-expanded="true"] {
        & > i {
          @include transition(all 0.2s ease);
          @include transform(rotate(180deg));
        }
      }
      &::after {
        content: none;
      }
      span {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        i {
          font-size: $font-size-10;
        }
      }
    }
    a {
      font-size: $font-size-14;
      color: $gray-900;
      span {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        i {
          font-size: 10px;
        }
      }
    }
    .dropdown-menu {
      width: 100%;
    }
  }
}
.star-icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid $light-900;
  color: $orange;
  i {
    font-size: $font-size-14;
  }
}
.select-pipeline {
  position: relative;
  margin-right: 8px;
  .pipe-icon {
    @include position(absolute, 50%, null, null, 10px);
    z-index: 99999;
    transform: translateY(-50%);
    color: $gray-900;
  }
  .select2-container {
    .select2-selection {
      &.select2-selection--single {
        height: 40px;
        border-radius: 5px;
        color: $gray-900;
        font-weight: $font-weight-medium;
        .select2-selection__rendered {
          padding-left: 30px;
          line-height: 40px;
          color: $gray-900;
          font-weight: $font-weight-medium;
          padding-right: 30px;
        }
        .select2-selection__arrow {
          height: 40px;
        }
      }
    }
  }
}
.pipeline-list {
  border-bottom: 1px solid $light-900;
  margin-bottom: 15px;
  ul {
    margin-bottom: 15px;
    li {
      display: inline-block;
      &:first-child {
        a {
          border-radius: 10px 0 0 10px;
        }
      }
      &:last-child {
        a {
          border-radius: 0;
        }
      }
      a {
        @include margin-padding(0 0 3px, 12px 25px 12px 50px);
        font-size: $font-size-14;
        font-weight: $font-weight-medium;
        display: inline-block;
        text-align: center;
        background: $light-300;
        color: $title-color;
        position: relative;
        min-width: 185px;
        &::after {
          content: "";
          @include position(absolute, 0, -23px, null, null);
          width: 45px;
          height: 45px;
          -webkit-transform: scale(0.707) rotate(45deg);
          -ms-transform: scale(0.707) rotate(45deg);
          -moz-transform: scale(0.707) rotate(45deg);
          -transform: scale(0.707) rotate(45deg);
          z-index: 1;
          background: $light-300;
          -webkit-box-shadow: 2px -2px 0 1px #fff,
            3px -3px 0 2px rgba(255, 255, 255, 0.1);
          box-shadow: 2px -2px 0 1px #fff,
            3px -3px 0 2px rgba(255, 255, 255, 0.1);
          border-radius: 0 5px 0 50px;
        }
        &.bg-info {
          color: $white;
          &::after {
            background: $info;
          }
          &:hover {
            background: $info !important;
          }
        }
        &.bg-warning {
          color: $white;
          &::after {
            background: $warning;
          }
          &:hover {
            background: $warning !important;
          }
        }
        &.bg-pending {
          color: $white;
          background: $purple !important;
          &::after {
            background: $purple;
          }
          &:hover {
            background: $purple !important;
          }
        }
        &.bg-pink {
          color: $white;
          &::after {
            background: $pink;
          }
          &:hover {
            background: $pink !important;
          }
        }
        &.bg-success {
          color: $white;
          &::after {
            background: $success;
          }
          &:hover {
            background: $success !important;
          }
        }
        &.bg-danger {
          color: $white;
          &::after {
            background: $danger;
          }
          &:hover {
            background: $danger !important;
          }
        }
        &.bg-gray {
          color: $white;
          background: $background-3;
          &::after {
            background: $background-3;
          }
          &:hover {
            background: $background-3 !important;
          }
        }
        @include respond-below(custom575) {
          width: calc(100% - 25px);
        }
      }
      @include respond-below(custom575) {
        display: block;
      }
    }
  }
}
.contact-tab-wrap {
  box-shadow: 0px 4px 54px 0px #e0e0e040;
  background: $white;
  border-radius: 5px;
  @include margin-padding(0 0 15px, 24px 24px 0);
  h4 {
    font-weight: $font-weight-semibold;
    margin-bottom: 20px;
    @include respond-below(custom767) {
      font-size: $font-size-16;
    }
  }
}
.contact-tab-view {
  box-shadow: 0px 4px 54px 0px #e0e0e040;
  background: $white;
  border-radius: 5px;
  @include margin-padding(0 0 24px, 24px);
}
.contact-nav {
  @include respond-below(custom575) {
    display: block;
  }
  li {
    display: inline-block;
    @include margin-padding(0 20px 0 0, null);
    button {
      color: $gray-400;
      background: none;
      border: none;
      outline: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      font-size: $font-size-14;
      font-weight: $font-weight-medium;
      border-bottom: 3px solid $white;
      text-align: center;
      @include margin-padding(null, 5px 5px 10px 5px);
      &:hover,
      &.active {
        color: $primary;
        border-color: $primary;
      }
      i {
        margin-right: 5px;
      }
    }
    @include respond-below(custom575) {
      display: block;
    }
  }
}
.form-sort {
  position: relative;
  i {
    position: absolute;
    z-index: 101;
    width: 16px;
    height: 16px;
    top: 14px;
    left: 7px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    padding-left: 35px;
  }
  span.select2-container.select2-container--default.select2-container--open {
    z-index: 99;
  }
}
.view-header {
  @include margin-padding(0 0 20px, 0 0 5px);
  border-bottom: 1px solid $light-900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
  h4 {
    font-weight: $font-weight-semibold;
    margin-bottom: 15px;
    @include respond-below(custom767) {
      font-size: $font-size-16;
    }
  }
  .com-add {
    font-size: $font-size-14;
    font-weight: $font-weight-medium;
    margin-bottom: 15px;
    color: $purple;
    &:hover {
      color: $primary;
    }
    &.create-mail {
      color: $primary;
    }
  }
  ul {
    li {
      display: inline-block;
      @include margin-padding(0 20px 15px 0, null);
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .form-sort {
    .select2-container {
      min-width: 150px !important;
      .select2-selection {
        .select2-selection__rendered {
          font-size: $font-size-14;
          color: $gray-900;
        }
      }
    }
  }
  .form-sort {
    margin-top: 0;
  }
}
.badge-day {
  background: $purple-100;
  color: $purple;
  border-radius: 5px;
  display: inline-block;
  font-size: $font-size-14;
  @include margin-padding(0 0 20px, 4px 8px);
  i {
    margin-right: 5px;
  }
}
.activity-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid $light-900;
  @include margin-padding(0 0 15px, 15px);
  border-radius: 5px;
  .activity-icon {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: $white;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .activity-info {
    h6 {
      font-weight: $font-weight-medium;
      margin-bottom: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      flex-wrap: wrap;
    }
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .avatar-xs {
      img {
        width: 24px;
        height: 24px;
        border-radius: 5px;
        @include margin-padding(0 8px 0, null);
      }
    }
  }
}
.contact-activity {
  ul {
    &:last-child {
      .activity-wrap {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.bg-pending {
  background: $violet !important;
  color: $white !important;
}
.bg-secondary-success {
  background: $green !important;
}
.bg-orange {
  background: $orange;
}
.upcoming-info {
  border: 1px solid $light-900;
  border-radius: 5px;
  @include margin-padding(10px 0 0, 15px 15px 5px);
  p {
    font-weight: $font-weight-medium;
    margin-bottom: 8px;
  }
  .dropdown {
    margin-bottom: 10px;
    .dropdown-toggle {
      color: $gray-900;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      font-size: $font-size-14;
      &::after {
        content: none;
      }
    }
    .avatar-xs {
      width: 16px;
      height: 16px;
      border-radius: 5px;
      margin-right: 10px;
    }
    .circle {
      font-size: 10px;
    }
    .dropdown-menu {
      button {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        font-size: $font-size-14;
        color: $gray-900;
      }
    }
  }
}
.action-drop {
  .dropdown-toggle {
    width: 33px;
    height: 33px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    border: 1px solid $light-900;
    color: $gray-900;
    &::after {
      content: none;
    }
  }
  .dropdown-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @include margin-padding(null, 5px);
    border-radius: 5px;
    color: $title-color;
    i {
      margin-right: 5px;
    }
    &:hover {
      background-color: $light-300;
      color: $title-color;
    }
  }
}
.notes-editor {
  .dropdown-toggle::after {
    content: none;
  }
}
.calls-activity {
  .calls-box {
    .caller-info {
      @include respond-below(custom767) {
        display: block;
      }
      .calls-action {
        @include respond-below(custom767) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }
    }
  }
}
.calls-box {
  @include margin-padding(0 0 20px, 24px);
  box-shadow: 0px 4px 74px 0px #d0d0d040;
  border-radius: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  .caller-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 5px;
    .calls-user {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      margin-bottom: 10px;
      img {
        width: 42px;
        height: 42px;
        border-radius: 5px;
        margin-right: 8px;
      }
      p {
        margin-bottom: 0;
        span {
          color: $gray-900;
          font-weight: $font-weight-medium;
        }
      }
    }
    .calls-action {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      .dropdown {
        margin: 0 8px 10px 0;
        &:last-child {
          margin-right: 0;
        }
        &.call-drop {
          .dropdown-toggle {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            background: $danger;
            @include margin-padding(null, 7px 12px);
            color: $white;
            font-size: $font-size-14;
            font-weight: $font-weight-semibold;
          }
        }
      }
      .dropdown-toggle {
        font-size: $font-size-14;
        border-radius: 5px;
        @include margin-padding(null, 0);
        color: $title-color;
        &::after {
          content: none;
        }
      }
      .dropdown-menu {
        box-shadow: 0px 4px 24px 0px #bcbcbc40;
        border: 1px solid $secondary-200;
        border-radius: 5px;
        padding: 10px;
        button {
          font-size: $font-size-14;
          &:hover {
            background-color: $light-300;
            color: $gray-900;
            border-radius: 5px;
          }
        }
      }
    }
  }
  p {
    font-size: $font-size-14;
  }
  .add-comment {
    font-weight: $font-weight-medium;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    color: $purple;
  }
}
.text-purple {
  color: $purple !important;
}
.note-edit-wrap {
  display: none;
  margin-bottom: 15px;
}
.notes-activity {
  .calls-box {
    h5 {
      font-weight: $font-weight-medium;
      margin-bottom: 7px;
    }
    p {
      margin-bottom: 15px;
    }
    .calls-user {
      h6 {
        font-weight: $font-weight-medium;
        margin-bottom: 0;
      }
    }
    & > ul {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      flex-wrap: wrap;
      & > li {
        display: inline-block;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        @include respond-below(custom575) {
          display: block;
          margin-right: 0;
        }
      }
      @include respond-below(custom575) {
        display: block;
      }
    }
  }

  .reply-box {
    background: $light-200;
    border-radius: 5px;
    @include margin-padding(0 0 15px, 15px);
    p {
      margin-bottom: 10px;
    }
    .btn {
      background: $white;
      border-color: $white;
      border-radius: 5px;
      color: $gray-900;
      font-size: $font-size-16;
      font-weight: $font-weight-normal;
      @include margin-padding(0, 5px 10px);
      i {
        margin-right: 3px;
      }
      &:hover {
        background: $light-100;
      }
    }
  }
}
.avatar-xxs {
  width: 16px;
  height: 16px;
}
.note-btns {
  margin-top: 5px;
  .btn {
    @include margin-padding(10px 10px 0 0, 7px 15px);
    &:last-child {
      margin-right: 0;
    }
    &.btn-primary {
      min-width: 110px;
    }
  }
}
.files-wrap {
  border: 1px solid $light-900;
  border-radius: 5px;
  @include margin-padding(0 0 15px, 24px 24px 9px);
  .file-info {
    margin-bottom: 15px;
    h4 {
      font-weight: $font-weight-semibold;
      margin-bottom: 5px;
      @include respond-below(custom767) {
        font-size: $font-size-16;
      }
    }
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .file-user {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
      }
      p {
        font-size: $font-size-14;
        margin-bottom: 0;
        span {
          display: block;
          font-size: $font-size-12;
        }
      }
    }
  }
  .file-action {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-bottom: 15px;
    li {
      display: inline-block;
    }
    .btn {
      @include margin-padding(null, 9px 19px);
      @include respond-below(custom767) {
        @include margin-padding(null, 8px 10px);
      }
    }
    .badge {
      font-size: $font-size-12;
      border-radius: 5px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      @include margin-padding(0 5px 0 0, 4px 5px);
      font-weight: $font-weight-normal;
      i {
        margin-right: 5px;
      }
      &.priority-badge {
        i {
          font-size: 4px;
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.drag-attach {
  border: 1px dashed $light-900;
  border-radius: 5px;
  position: relative;
  text-align: center;
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
  .img-upload {
    background: $purple-100;
    color: $purple;
    font-size: $font-size-14;
    display: inline-block;
    text-align: center;
    @include margin-padding(24px 0, 9px 17px);
    border-radius: 5px;
    i {
      margin-right: 10px;
    }
  }
}
.drag-upload {
  background: $light-100;
  border: 1px dashed $secondary-200;
  border-radius: 5px;
  position: relative;
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
  i {
    font-size: $font-size-24;
    margin-bottom: 10px;
  }
  p {
    font-size: $font-size-14;
    margin-bottom: 0;
  }
  .img-upload {
    text-align: center;
    @include margin-padding(null, 36px 20px);
  }
}
.upload-file {
  background: $light-200;
  border: 1px solid $light-900;
  @include margin-padding(0 0 5px, 15px);
  border-radius: 5px;
  h6 {
    font-size: $font-size-14;
    font-weight: $font-weight-medium;
    margin-bottom: 2px;
  }
  p {
    font-size: $font-size-14;
    margin-bottom: 0;
    &.black-text {
      color: $gray-900;
    }
  }
  .progress {
    height: 3px;
    @include margin-padding(15px 0 4px, null);
  }
  &.upload-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
  }
}
.form-title {
  font-weight: $font-weight-semibold;
  margin-bottom: 15px;
}
.sync-radio {
  .radio-item {
    margin-bottom: 15px;
    label {
      font-size: $font-size-14;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.signature-wrap {
  background: $light-700;
  border: 1px solid $light-900;
  border-radius: 5px;
  @include margin-padding(0 0 15px, 15px 15px 0);
  h4,
  p {
    margin-bottom: 15px;
  }
  h4 {
    font-weight: $font-weight-semibold;
    @include respond-below(custom767) {
      font-size: $font-size-16;
    }
  }
}
.add-sign {
  font-size: $font-size-16;
  color: $warning;
}
.sign-item {
  li {
    margin-bottom: 15px;
    .sign-title {
      display: block;
      font-weight: $font-weight-medium;
      margin-bottom: 3px;
    }
  }
}
.trash-sign {
  color: $danger;
}
.send-success {
  border: 1px solid $success;
  background: $success-300;
  border-radius: 5px;
  @include margin-padding(0 0 15px, 15px);
  p {
    color: $gray-900;
    margin-bottom: 0;
    i {
      margin-right: 5px;
    }
  }
}
fieldset {
  display: none;
  &#first-field-file {
    display: block;
  }
}
.add-details-wizard {
  ul {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    @include respond-below(custom575) {
      flex-direction: column;
    }
    li {
      line-height: normal;
      background: $light-300;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      @include respond-below(custom575) {
        width: 100%;
        margin-bottom: 10px;
      }
      span {
        margin-right: 5px;
      }
      .multi-step-info {
        h6 {
          margin-bottom: 0;
          font-weight: $font-weight-medium;
          font-size: $font-size-14;
          color: $gray-400;
        }
      }
      &.active {
        background: $primary;
        color: $white;
        h6 {
          color: $white;
        }
      }
    }
  }
}
.pipeline-item {
  border: 1px solid $light-900;
  @include margin-padding(0 0 5px, 15px 15px 5px);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  &::before {
    @include position(absolute, 0, null, null, 0);
    content: "";
    width: 2px;
    height: 100%;
    background: $secondary;
  }
  p {
    color: $gray-900;
    font-weight: $font-weight-medium;
    margin-bottom: 10px;
  }
  .action-pipeline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    flex-wrap: wrap;
    a {
      @include margin-padding(0 15px 10px 0, null);
      i {
        margin-right: 8px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.access-item {
  .radio-btn {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.access-wrapper {
  margin-top: 20px;
}
.access-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  border: 1px solid $light-900;
  @include margin-padding(0 0 5px, 15px 15px 5px);
  border-radius: 5px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  .access-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    img {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
  a {
    font-weight: $font-weight-semibold;
    color: $danger;
  }
}
.header-collapse {
  .header-user {
    display: none;
    height: 0;
  }
  .page-wrapper {
    padding-top: 0;
  }
  .header {
    border-bottom: none;
    background: transparent;
    width: 260px;
    .header-left {
      background: white;
    }
  }
  #collapse-header {
    i {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
    }
  }
}
.bootstrap-datetimepicker-widget {
  z-index: 99999;
  table {
    td {
      &.active,
      &:hover {
        background-color: $green;
      }
    }
  }
}
.note-modal-backdrop {
  z-index: 9;
}
.note-modal-footer {
  height: 57px;
}
.table tbody tr td a {
  &.title-name {
    color: $gray-900;
    font-weight: $font-weight-medium;
    &:hover {
      color: $primary;
    }
  }
}
.title-name {
  color: $gray-900;
  font-weight: $font-weight-medium;
}

.note-download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border: 1px solid $light-900;
  box-shadow: 0px 4px 4px 0px #dbdbdb40;
  @include margin-padding(0 0 15px, 15px);
  border-radius: 5px;
  .note-info {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: flex;
    width: 85%;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    h6 {
      font-weight: $font-weight-medium;
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 0;
    }
    @include respond-below(custom767) {
      margin-right: 15px;
    }
  }
  .note-icon {
    width: 44px;
    height: 44px;
    border-radius: 5px;
    color: $white;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-right: 8px;
    flex-shrink: 0;
    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }
  }
  &:hover {
    background: $light-300;
    a {
      background: $white;
    }
  }
  a {
    width: 24px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    border-radius: 50%;
    color: $title-color;
    background: $light-300;
  }
  @include respond-below(custom767) {
    @include margin-padding(0 0 15px, 10px);
  }
}

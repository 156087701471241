.dropdownContainer input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none !important;
}

.container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  font-size: 11px;
  border: none;
  background: #f9f9f9;
  font-size: 10px;
  padding: 0 10px;
  border: 1px solid #e8e8e8;
  height: 42px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.container select::-ms-expand {
  display: none;
}

.warning {
  border-color: red !important;
}

.togglePasswordButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #262a2a;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}
.togglePasswordButton:hover {
  color: #e41f07;
}

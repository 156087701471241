.page-wrapper {
    @include transition(all 0.5s ease);
    @include margin-padding( 0 0 0 276px, 56px 0 0);
    @include position(relative,null,null,null,0);
    @include transition(all 0.2s ease);
    @include respond-below(custom991) {
        @include margin-padding( 0 , 60px 0 0);
    }
    .content {
        @include margin-padding(null, 24px);
        padding-bottom: 0;
        @include respond-below(custom991) {
            @include margin-padding(null, 15px 15px 0);
        }
    }
}

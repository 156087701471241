.card {
    &.analytics-card {
        background: $white;
        padding: 24px;
        border-color: $white;
        box-shadow: 0px 4px 24px 0px #BCBCBC40;
        .card-header {
            padding: 0;
            background: $white;
            border: 0;
            margin-bottom: 14px;
            @include respond-below(custom575) {
                flex-direction: column;
                align-items: start !important;
            }
            h3 {
                font-size: $font-size-18;
                font-weight: $font-weight-semibold;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                i {
                    margin-right: 5px;
                }
                a {
                    color: $title-color;
                    margin-left: 5px;
                }
                @include respond-below(custom575) {
                    font-size: $font-size-16;
                }
            }
            .select2-container--default .select2-selection--single .select2-selection__rendered {
                font-size: $font-size-14;
                font-weight: $font-weight-medium;
                color: $gray-900;
            }
            .select2-results__option {
                font-size: $font-size-14;
            }
            .card-select {
                ul {
                    li {
                        display: inline-block;
                        @include margin-padding(0 10px 10px 0, null);
                        .btn {
                            i {
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }
        .card-body {
            padding: 0;
            .table {
                th {
                    padding: 10px;
                    background: $light-200;
                    color: $title-color;
                    &:first-child {
                        border-left: 1px solid $secondary-100;
                    }
                    &:last-child {
                        border-right: 1px solid $secondary-100;
                    }
                }
                td {
                    &:first-child {
                        border-left: 1px solid $secondary-100;
                    }
                    &:last-child {
                        border-right: 1px solid $secondary-100;
                    }
                }
            }
        }
    }
}
.activities-list {
    ul {
        li {
            background: $light-300;
            padding: 15px 10px;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .activity-name {
                h5 {
                    font-size: $font-size-14;
                    font-weight: $font-weight-medium;
                    color: $gray-900;
                    margin-bottom: 5px;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                }
                p {
                    font-size: $font-size-14;
                    margin-bottom: 15px;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                }
                .badge {
                    color: $white;
                    @include margin-padding(null, 5px 7px);
                    font-size: $font-size-12;
                    @include rounded(5px);
                    i {
                        margin-right: 5px;
                    }
                }
            }
            .user-activity {
                display: flex;
                align-items: center;
                @include respond-below(custom767) {
                    margin: 20px 0;
                }
               
                span {
                    flex-shrink: 0;
                    display: inline-flex;
                    margin-right: 5px;
                    img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                    }
                }
                h6 {
                    margin-bottom: 0;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                }
            }
        }
    }
}
.card-select {
    ul {
        @include respond-below(custom575) {
            flex-direction: column;
            align-items: start !important;
        }
    }
}
.campaign-card {
    overflow-x: auto;
    ul {
        width: max-content;
        .campaign-wrap {
            background-color: $light-200;
            @include margin-padding(0 0 15px, 15px 15px 5px);
            .campaign-info {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: space-between;
                -webkit-justify-content: space-between;
                white-space: nowrap;
                border-bottom: 1px solid $light-900;
                margin: 0 0 15px 0;
                h5 {
                    font-weight: $font-weight-semibold;
                    margin-bottom: 5px;
                }
                .campaign-name {
                    margin: 0 50px 10px 0;
                }
                .list-progress {
                    margin-bottom: 10px;
                }
            }
            .campaign-action {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: space-between;
                -webkit-justify-content: space-between;
                flex-wrap: wrap;
                .project-mem {
                    margin-bottom: 10px;
                }
            }
            .campaign-date {
                margin-bottom: 10px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                .badge {
                    margin: 0 15px 0 0;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
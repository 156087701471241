.sidebar-contact {
    position: fixed;
    top: 0%;
    right: -350px;
     transform: translateY(-50%); 
    width: 350px;
    height: auto;
    background: #fff;
    box-sizing: border-box;
    transition: 0.5s;
    z-index: 9999;
    .toggle-theme {
        position: absolute;
        width: 35px;
        text-align: center;
        cursor: pointer;
        background: #fed713;
        top: 225px;
        right: 350px;
        padding: 6px;
        height: 35px;
        color: #000;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        .fa-spin {
            -webkit-animation: fa-spin 2s infinite linear;
            animation: fa-spin 2s infinite linear;
        }
    }
    
}      
#theme-settings{
    width: 26px;
    height: 26px;
    border: 1px solid $light-900;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}                                                                 
.sidebar-themesettings{
    position: fixed;
    right: -380px;
    top: 0;
    background: #fff;
    transition: all 0.5s;
    width: 380px;
    height: 100vh;
    z-index: 99999;
    padding: 15px;
    overflow: auto;
    &.open{
        right: 0; 
    }
    .themesettings-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid $light-900;
        h4{
            color: $gray-900;
            font-size: 18px;
        }
    }
    .themesettings-inner{
        padding: 24px;
        background: #fff;
        border: 1px solid $light-900;
        border-radius: 5px;
        margin-top: 15px;
        padding-bottom: 9px;
        .themesettings-content{
            border-bottom: 1px solid $light-900;
            margin-bottom: 15px;
            h6{
                font-size: 14px;
                font-weight: 600;
                color: #000; 
                margin-bottom: 15px;
            }
        }
    }
    .input-themeselect{
        background: $light-500;
        padding: 10px;
        border-radius: 10px;
        border:1px solid $light-900;
        margin-bottom: 15px;
        input[type="radio"]{
            display: none;
            &:checked{
                ~ label{
                    .checkboxs-theme{
                        &:after{
                            background-color: $purple;
                            border-color: $purple;
                            content: "";
                        }
                        &::before{
                            content: "\f00c";
                            font-family: "Font Awesome 5 Free";
                            color: #fff;
                            font-weight: 700;
                            font-size: 10px;
                            right: 4px;
                            position: absolute;
                            top: -15px;
                            z-index: 9;
                        }
                    } 
                }
            }
        }
        img{
            height: 80px;
        }
        .checkboxs-theme{
            position: relative;
            width: 100%;
            display: block;
            &:after{
                position: absolute;
                content: "";
                border: 1px solid #E8E8E8;
                width: 16px;
                height: 16px;
                border-radius: 5px;
                right: 0;
                top: -17px;
            }
        }
    }
}
.sidebar-themeoverlay{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.75);
    top: 0;
    left: -100%;
    z-index: 10000;
    &.open{
        left: 0;
    }
}
.theme-colorsset{
    margin: 15px 0;
    ul{
        display: flex;
        align-items: center;
        li{
            .input-themeselects{
                label{
                    width: 30px;
                    height: 30px;
                    border-radius: 2px;
                    position: relative;
                    &.red-clr{
                        background: $primary;
                    }
                    &.yellow-clr{
                        background: $secondary;
                    }
                    &.blue-clr{
                        background: #3C2371;
                    }
                    &.green-clr{
                        background: #00918E;
                    }
                }
                input[type="radio"]{
                    display: none;
                    &:checked{
                        ~ label{
                            &:after{
                                content: "\f00c";
                                font-family: "Font Awesome 5 Free";
                                color: #fff;
                                font-weight: 700;
                                font-size: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                top: 10px;
                                left: 10px;
                                position: absolute;
                            }
                        }
                    }
                }
            }
            + li{
                margin-left: 10px;
            }
        }
    }
}
.themesettings-footer{
    margin-top: 15px;
    ul{
        display: flex;
        align-items: center;
        li{
            width: 50%;
            + li{
                margin-left: 10px;
            }
            .btn{
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                padding: 8px;
            }
            .btn-cancel{
                background: $light-500;
                border: 1px solid $light-900;
                color: $gray-900;
            }
            .btn-reset{
                background: $green;
                color: #fff;
            }
        }
    }
}
.customer-profile-card {
  padding-block: 60px;
  &:hover {
    background: rgba(240, 240, 240);
    cursor: pointer;
  }

  i {
    font-size: 50px;
  }
}

.social-card {
  &:hover {
    background: #d6d6d6;
  }
}
@import "./style/scss/main.scss";
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primeicons/primeicons.css";
@import "primereact/resources/primereact.css";
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "react-time-picker/dist/TimePicker.css";
@import "react-clock/dist/Clock.css";

.Toastify__toast-body {
  white-space: pre-line;
}

.color-picker {
  z-index: 1321313133;
}

.apexcharts-canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
}

.badge-announce {
  font-size: 12px !important;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 500;
  border-radius: 0px;
  border-top-right-radius: 5px;
}

.product-card-single-table {
  border: 1px solid #cacaca !important;

  th {
    border-top: 1px solid #cacaca !important;
    border-bottom: 1px solid #cacaca !important;
    background-color: #f5f5f5 !important;
  }

  td  {
    border: 1px solid #cacaca !important;
  }
}

.offer-table {
  thead th {
    color: #667382;
  }

  th {
    font-size: 12px !important;
    font-weight: 600 !important;
    border-color: #cacaca !important;
  }

  td {
    border-color: #cacaca !important;
    font-weight: initial !important;
  }
}

a {
  text-decoration: none;
  color: black;
}

.ant-tooltip {
  display: none;
}

.data-table-card {
  .ant-table-content {
    .ant-table-tbody {
      tr {
        &:nth-of-type(2n + 1) {
          background-color: #fff;
        }
      }

      td {
        padding: 0.5rem;
      }
    }
  }
}

.ant-table-cell-row-hover {
  background-color: #fff;
  background: #fff;
}

//ant datatable pagination
.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
  background: #00918e;
  border-color: #00918e;
  color: #fff;
}

// manage columns in datatable page
h4,
.h4 {
  font-size: 1.125rem;
}

// date range picker
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #00918e;
  color: #fff;
}

.daterangepicker .calendar-table td.in-range {
  background-color: #fff;
}

.applyBtn.btn.btn-sm.btn-primary {
  color: #fff;
  background-color: #00918e;
  border: 1px solid #00918e;
}

// react-select plugin
.css-1u9des2-indicatorSeparator {
  display: none;
}

//compaign data table
div .badge-status {
  border-radius: 5px;
  font-size: $font-size-12;
  padding: 4px 8px;
  min-width: 64px;
  color: $white;
}

div .bg-success {
  background-color: $success !important;

  &.bg-opacity-10 {
    background-color: rgba($success, 0.1) !important;
  }

  &.bg-opacity-25 {
    background-color: rgba($success, 0.25) !important;
  }

  &.bg-opacity-50 {
    background-color: rgba($success, 0.5) !important;
  }

  &.bg-opacity-75 {
    background-color: rgba($success, 0.75) !important;
  }

  &.bg-opacity-100 {
    background-color: rgba($success, 1) !important;
  }
}

// compaign page datatable
// .list-progress {
//   width: 380px;
// }
:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background-color: #fff;
}

// multi-select
// .css-1xc3v61-indicatorContainer svg {
//   display: none;
// }

// .css-15lsz6c-indicatorContainer svg {
//   display: none;
// }

// .css-tj5bde-Svg {
//   background-color: #F6F6F6;
//   border-color: #F6F6F6;
//   color: #6F6F6F;
//   border-radius: 5px;
// }
.css-1p3m7a8-multiValue {
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  color: #6f6f6f;
  border-radius: 5px;
}

.stickybar {
  top: 0;
  position: sticky;
}

// select
.select-details .css-1dimb5e-singleValue,
.select-details .css-1jqq78o-placeholder {
  padding-left: 15px;
}

div.css-13cymwt-control,
div.css-t3ipsp-control {
  min-width: 120px;
  // border-color: #E8E8E8;
  // color: #6F6F6F;
  // box-shadow: 0px 4px 4px 0px #DBDBDB40;
}

div.css-t3ipsp-control:hover {
  border-color: transparent;
}

// datepicker
.deals-details.react-datepicker__input-container {
  width: 450px !important;
}

div.react-datepicker-wrapper {
  display: block;
  padding: 0;
  border: 0;
}

span.form-icon {
  z-index: 1;
}

.img-checked {
  padding: 10px;
  // width: 30px;
  background: rgb(49, 132, 199);
  color: white;
  border-radius: 20px;
  font-weight: bolder;
  position: relative;
  /* margin-top: 2px ; */
}

.section-notes-slider .notes-slider .slick-list .slick-slide div {
  margin-left: 12px !important;
}

.page-add-notes {
  display: flex;
  flex-wrap: wrap;
}

.visible .input-blocks .css-b62m3t-container .css-1dimb5e-singleValue {
  margin-left: 25px !important;
}

[data-layout-mode="dark_mode"] .css-13cymwt-control {
  background-color: #1d1d42;
  border: 1px solid#67748E;
}

[data-layout-mode="dark_mode"] .css-t3ipsp-control {
  background-color: #1d1d42;
}

[data-layout-mode="dark_mode"] .css-1nmdiq5-menu {
  background-color: #1d1d42;
}

// .bx-microphone:before {
//   content: "\eb89";
// }
.form-sorts.dropdown .radio-btn-items {
  margin-top: 10px;
}

.select__control {
  background-color: #e41f07 !important;
  border-color: #e41f07 !important;
}

.select__control:hover {
  border-color: #e41f07 !important;
}

// .slick-slide {
//   margin-right: 5px;
// }
.p-dropdown-label.p-inputtext .p-d-flex.p-ai-center img,
.p-dropdown-item-label .p-d-flex.p-ai-center,
.p-dropdown-label.p-inputtext .p-clearfix img,
.p-dropdown-item-label .p-clearfix img {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 5px;
}

.p-d-flex.p-ai-center {
  display: flex;
  align-items: center;
}

.p-dropdown-items-wrapper ul.p-dropdown-items,
.p-dropdown-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
  z-index: 100000000000 !important;
}

.swiper-container .slick-list {
  margin-left: -236px;
}

.p-dropdown.p-component.p-inputwrapper {
  height: 42px;
  line-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #e8e8e8;
  color: #6f6f6f;
  box-shadow: 0px 4px 4px 0px #dbdbdb40;
}

div.p-clearfix {
  display: flex;
  align-items: center;
}

div.p-clearfix span,
span.p-dropdown-label.p-inputtext.p-placeholder {
  font-size: 14px;
}

.p-dropdown-trigger svg {
  width: 13px;
}

div.rti--container {
  border-color: #e8e8e8;
  color: #6f6f6f;
  box-shadow: 0px 4px 4px 0px #dbdbdb40;
}

span.rti--tag button {
  font-size: 10px;
}

.filter-dropdown-menu.dropdown-menu.dropdown-menu-md-end.show,
.filter-dropdown-menu.dropdown-menu {
  z-index: 11;
}

span.ant-picker-suffix {
  display: none !important;
}

.stickybar {
  position: sticky;
  top: 0;
}

.right-user-side.chat-rooms.theiaStickySidebar {
  max-height: 500px;
  overflow: auto;
}

.send-chat.comman-icon i.feather.feather-send {
  color: #fff;
}

.chat-footer form {
  background-color: #fff;
}

.nav.flex-column.nav-pills.todo-inbox i {
  margin-right: 10px;
}

.select-priority .css-1jqq78o-placeholder,
.select-priority .css-1dimb5e-singleValue {
  padding-left: 15px;
}

.form-sort .css-1jqq78o-placeholder,
.form-sort .css-1dimb5e-singleValue {
  padding-left: 20px;
}

.form-sort.select-bluk.space-not-select .css-1jqq78o-placeholder,
.form-sort.select-bluk.space-not-select .css-1dimb5e-singleValue {
  padding: 0;
}

.owl-carousel.folders-carousel.owl-theme .slick-slide > div,
.owl-carousel.files-carousel.owl-theme .slick-slide > div {
  margin-right: 10px;
}

[data-theme="dark"] .ant-table-content table,
[data-theme="dark"] .ant-table-thead tr th,
[data-theme="dark"]
  .ant-pagination.ant-table-pagination.ant-table-pagination-right {
  background-color: #171724;
  color: #a3a6a6;
}

[data-theme="dark"] .table tbody tr td .table-avatar a,
[data-theme="dark"] td.ant-table-cell {
  color: #a3a6a6;
}
a#theme-settings i {
  color: #262a2a;
}

.badge {
    font-size: 13px !important;
  &.badge-primary {
    background: rgba($primary, 0.1);
    box-shadow: 0 1px 1px rgba($primary, 0.5);
    color: $primary;
  }
}
@each $color, $value in $theme-colors {
  .badge {
    &.badge-#{$color} {
      background: #{$value} !important;
      box-shadow: 0 1px 1px rgba($value, 0.5) !important;
      color: $white !important;
    }
    &.badge-soft-#{$color} {
      background: rgba($value, 0.1) !important;
      box-shadow: 0 1px 1px rgba($value, 0.5) !important;
      color: $value !important;
    }
  }
}
.badge {
  padding: 0.25rem 0.45rem;
  text-transform: capitalize;
  font-weight: $font-weight-semibold;
  letter-spacing: 0.5px;
  border-radius: $border-radius;
  color: $gray-900;
  &.badge-xs {
    font-size: 10px;
    padding: 0px 5px;
    line-height: 18px;
  }
  &.badge-sm {
    font-size: 11px;
    padding: 5px 8px;
    line-height: 11px;
  }
  &.badge-md {
    font-size: 12px;
    padding: 5px 12px;
    line-height: 1.5;
  }
  &.badge-lg {
    font-size: $font-size-14;
    padding: 0px 10px;
    line-height: 30px;
  }
  &.badge-xl {
    font-size: $font-size-16;
    padding: 0px 15px;
    line-height: 35px;
  }
}
.bg-light-danger {
  background-color: $danger-100 !important;
  .badge-dots {
    background-color: $danger-900;
  }
}
.bg-light-success {
  background-color: $success-100 !important;
  .badge-dots {
    background-color: $success-900;
  }
}
.bg-light-info {
  background-color: $info-100 !important;
  .badge-dots {
    background-color: $info-900;
  }
}
.bg-light-warning {
  background-color: $warning-100 !important;
  .badge-dots {
    background-color: $warning-900;
  }
}
